import React from 'react'
import { Link } from 'gatsby'
import sidebarLogo from '../../images/cannatimes-temp-logo-dark.png'
import magazineImg from '../../images/magazine-cover-preview.jpg'

class App extends React.Component {
  render() {
    return (
      <div className="navigation-list">
        <div className="navigation text-center">
          <img src={sidebarLogo} alt="Sidebar Logo" className="sidebar-logo" />
          <ul className="list-unstyled main-nav">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/latest-articles">News</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact</Link>
            </li>
            <li>
              <Link to="/advertise-with-us">Advertise With Us</Link>
            </li>
          </ul>
          <img src={magazineImg} alt="Magazine" />
          <Link to="/latest-articles" className="btn btn-primary gradient-btn mt-2">
            Grab the Latest Issue
          </Link>
        </div>
      </div>
    )
  }
}

export default App
