import React from 'react'
import { Link } from 'gatsby'
// import style from './index.module.css'
import footerLogo from '../../images/cannatimes-temp-logo.jpg'

const Footer = ({ siteTitle }) => (
  <div className="footer">
    <div className="container">
      <div>
        <div className="footer-logo">
          <img src={footerLogo} alt="Footer Logo" className="img-fluid" />
        </div>
        <ul className="footer-links">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/latest-articles">News</Link>
          </li>
          {/*
          <li>
            <Link to="/events">Events</Link>
          </li>
          */}
          <li>
            <Link to="/advertise-with-us">Advertise With Us</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact</Link>
          </li>
        </ul>
      </div>
      <div className="copyright">
        <span>&copy; 2019 Colorado CannaTimes. All Rights Reserved.</span>
        <span>
          Designed and Powered by&nbsp;
          <a
            href="https://www.webriq.com/"
            target="_blank"
            rel="noopener noreferrer"
            alt="WebriQ"
          >
            WebriQ
          </a>
          .
        </span>
      </div>
    </div>
  </div>
)

export default Footer
