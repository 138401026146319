import React from 'react'
import { Link } from 'gatsby'
// import style from './index.module.css'
import logo from '../../images/cannatimes-temp-logo.jpg'
import ads from '../../images/sample-ad.png'
import 'react-sticky-header/styles.css'
import SideBarNav from '../sidebar'
import sidebarBtn from '../../images/bars.svg'
import SideBar from 'react-sidebar'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      sidebarpullRight: true,
    }
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
  }
  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open })
  }
  render() {
    return (
      <div>
        <div id="main-nav">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-6 logo-wrap p-0 align-self-center header-nav1">
                <Link className={`logo px-2`} to="/">
                  <img src={logo} alt="PCS Colorado" />
                </Link>
              </div>
              <div className="d-md-block d-none col-md-6 align-self-center header-nav1">
                <img src={ads} alt="ads" className="img-fluid py-2" />
              </div>
              <div className="col-md-2 col-6 header-btn-wrap align-self-center text-right p-4 header-nav">
                {/*<SideBarNav />*/}
                <div className="navigation-menu">
                  <ul className="m-0">
                    <li className="list-inline-item nav_content">
                      <span
                        className="nav_handle"
                        onClick={() => this.onSetSidebarOpen(true)}
                      >
                        <img src={sidebarBtn} alt="Sidebar Button" />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SideBar
          sidebar={<SideBarNav />}
          open={this.state.sidebarOpen}
          onSetOpen={this.onSetSidebarOpen}
          pullRight={this.state.sidebarpullRight}
          styles={{
            sidebar: {
              width: '265px',
              position: 'fixed',
              zIndex: '100',
              background: 'rgb(255, 255, 255)',
            },
          }}
        >
          <p />
        </SideBar>
      </div>
    )
  }
}

export default Header
